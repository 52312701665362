import { Route, Router, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import PendingData from './components/PendingData';
import CompleteData from './components/CompleteData';
import PendingViewBill from './components/PendingViewBill';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/pending-status' element={<PendingData/>} />
      <Route path='/completed-status' element={<CompleteData/>} />
      <Route path='/pending-view-bill' element={<PendingViewBill/>} />
    </Routes>

  );
}

export default App;
