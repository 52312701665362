import React from 'react';
import { IoMdLogOut } from 'react-icons/io'; // Ensure you have this import
import { useNavigate } from 'react-router-dom';

function Header({ handleToggle }) {
    const navigate = useNavigate();

    const handleLogOut = () => {
        localStorage.removeItem('token');
        navigate('/', { replace: true });
    };

    return (
        <div className='container-fluid bg-white header d-flex align-items-center justify-content-between py-2'>
            <div className='d-flex align-items-center'>
                <div className='fs-3 text mr-3'>Logo</div> {/* Use mr-3 for margin-right */}
                <button type="button" className="btn btn-outline-none" style={{ marginLeft: "100px" }} onClick={handleToggle}>
                    <div className='toggle-btn'></div>
                    <div className='toggle-btn'></div>
                    <div className='toggle-btn'></div>
                </button>
            </div>
            <div>
                <button className="btn btn-outline-danger d-flex align-items-center justify-content-center gap-1" onClick={handleLogOut}>
                    <span className="mr-2">LOGOUT</span>
                    <IoMdLogOut size={17} />
                </button>
            </div>
        </div>
    );
}

export default Header;
