import React, { useEffect, useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import baseUrl from './Constant';
import axios from 'axios';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';

function CompleteData() {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [invoiceList, setInvoiceList] = useState([]);

    const handleToggle = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const getInvoiceList = async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        console.log(token);
        try {
            const res = await axios.get(`${baseUrl}/accounts/unpaidinvoice/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(res.data.invoice);
            setInvoiceList(res.data.invoice); // Set the invoice list state with the fetched data
        } catch (error) {
            console.error('Error fetching invoice list:', error);
        }
    };

    useEffect(() => {
        getInvoiceList();
    }, []);

    return (
        <div>
            <Header handleToggle={handleToggle} />
            <div className='d-flex'>
                <Sidebar isOpen={sidebarOpen} />
                <div className='container py-3'>
                    <div className='card p-3'>
                        <MDBTable hover>
                            <MDBTableHead>
                                <tr>
                                    <th className='p-3' scope='col'>Sr.No</th>
                                    <th className='p-3' scope='col'>Invoice No</th>
                                    <th className='p-3' scope='col'>Order Date</th>
                                    <th className='p-3' scope='col'>Amount Status</th>
                                    <th className='p-3' scope='col'>Action</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {invoiceList.map((invoice, index) => (
                                    
                                        <tr key={`${invoice.uid}-${invoice.uid}`}>
                                            <th className='p-3' scope='row'>{index + 1}</th>
                                            <td className='p-3'>{invoice.invoice_no}</td>
                                            <td className='p-3'>{invoice.ordered_date}</td>
                                            <td className='p-3'>{
                                                invoice.is_paid ?"paid":"unpaid"
                                                }</td>
                                            <td>
                                               <button className='btn btn-info'>
                                                View Bill
                                               </button>
                                            </td>
                                        </tr>
                                    
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompleteData;
