import React, { useState, useEffect } from 'react';
import '../Css/Login.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import baseUrl from './Constant';

function Login() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const navigate = useNavigate();

    const handleSendOtp = async (e) => {
        e.preventDefault();
        const loadingToast = toast.loading("Sending OTP...");
        try {
            const res = await axios.post(`${baseUrl}/accounts/user-login/`, {
                phone_number: phoneNumber,
            });
            if (res.data.status === 200) {
                setOtpSent(true);
                toast.update(loadingToast, {
                    render: 'OTP sent successfully!',
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000,
                });
            } else {
                toast.update(loadingToast, {
                    render: res.data.message,
                    type: 'error',
                    isLoading: false,
                    autoClose: 3000,
                });
            }
        } catch (error) {
            console.log(error);
            toast.update(loadingToast, {
                render: 'Failed to send OTP. Please try again.',
                type: 'error',
                isLoading: false,
                autoClose: 3000,
            });
        }
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        const loadingToast = toast.loading("Verifying OTP...");
        try {
            const res = await axios.post(`${baseUrl}/accounts/verify-otp/`, {
                phone_number: phoneNumber,
                otp: otp,
            });
            if (res.data.status === 200) {
                const token = res.data.access;
                localStorage.setItem('token', JSON.stringify(token));
                toast.update(loadingToast, {
                    render: 'Login successful!',
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000,
                });
                navigate('/dashboard');
            } else {
                toast.update(loadingToast, {
                    render: res.data.message,
                    type: 'error',
                    isLoading: false,
                    autoClose: 3000,
                });
            }
        } catch (error) {
            console.log(error);
            toast.update(loadingToast, {
                render: 'Login failed. Please try again.',
                type: 'error',
                isLoading: false,
                autoClose: 3000,
            });
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/dashboard');
        } else {
            navigate('/');
        }
    }, [navigate]);

    return (
        <div className='login-con'>
            <div className="wrapper bg-white">
                <div className="h4 text-muted text-center pt-2">Enter your login details</div>
                {!otpSent ? (
                    <form className="pt-3" onSubmit={handleSendOtp}>
                        <div className="form-group py-2">
                            <div className="input-field">
                                <span className="far fa-user p-2"></span>
                                <input 
                                    type="text" 
                                    placeholder="Phone Number" 
                                    required 
                                    value={phoneNumber} 
                                    onChange={(e) => { setPhoneNumber(e.target.value) }} 
                                />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-block text-center my-3">Send OTP</button>
                    </form>
                ) : (
                    <form className="pt-3" onSubmit={handleVerifyOtp}>
                        <div className="form-group py-2">
                            <div className="input-field">
                                <span className="fas fa-lock p-2"></span>
                                <input 
                                    type="text" 
                                    placeholder="Enter OTP" 
                                    required 
                                    value={otp} 
                                    onChange={(e) => { setOtp(e.target.value) }} 
                                />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-block text-center my-3">Verify OTP</button>
                    </form>
                )}
            </div>
        </div>
    );
}

export default Login;
