import React, { useEffect, useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import baseUrl from './Constant';
import axios from 'axios';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';

function PendingData() {
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [invoiceList, setInvoiceList] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state

    const handleToggle = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const getInvoiceList = async () => {
        const token = JSON.parse(localStorage.getItem('token'));
        console.log(token);
        setLoading(true); // Set loading to true when fetching starts
        try {
            const res = await axios.get(`${baseUrl}/accounts/unpaidinvoice/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(res.data);
            setInvoiceList(res.data.invoice); // Set the invoice list state with the fetched data
        } catch (error) {
            console.error('Error fetching invoice list:', error);
        } finally {
            setLoading(false); // Set loading to false after data is fetched
        }
    };

    const handleViewBill = (item) => {
        console.log(item.uid);
        navigate('/pending-view-bill', { state: { uid: item } });
    };

    useEffect(() => {
        getInvoiceList();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Display loading message
    }

    return (
        <div>
            <Header handleToggle={handleToggle} />
            <div className='d-flex'>
                <Sidebar isOpen={sidebarOpen} />
                <div className='container py-3'>
                    <div className='card p-3'>
                        <MDBTable hover>
                            <MDBTableHead>
                                <tr>
                                    <th className='p-3' scope='col'>Sr.No</th>
                                    <th className='p-3' scope='col'>Invoice No</th>
                                    <th className='p-3' scope='col'>Order Date</th>
                                    <th className='p-3' scope='col'>Amount Status</th>
                                    <th className='p-3' scope='col'>Amount </th>
                                    <th className='p-3' scope='col'>Action</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {invoiceList.map((item, index) => (
                                    <tr key={`${item.uid}-${item.uid}`}>
                                        <th className='p-3' scope='row'>{index + 1}</th>
                                        <td className='p-3'>{item.invoice_no}</td>
                                        <td className='p-3'>{item.ordered_date}</td>
                                        <td className='p-3'>
                                            {item.is_paid ? "paid" : "unpaid"}
                                        </td>
                                        <td className='p-3'>{item.amount}</td>

                                        <td>
                                            <button
                                                className='btn btn-info'
                                                onClick={() => handleViewBill(item.uid)}
                                            >
                                                View Bill
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PendingData;
