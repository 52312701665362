import React from 'react';
import { MdDashboard } from "react-icons/md";
import { MdOutlinePendingActions } from "react-icons/md";
import { MdIncompleteCircle } from "react-icons/md";
import { Link } from 'react-router-dom';
import '../Css/Sidebar.css'

function Sidebar({ isOpen }) {

    const sidebarStyle = {
        width: isOpen ? '220px' : '80px',
        transition: 'width 0.3s ease',
        display: isOpen ? 'block' : 'flex',
        justifyContent: isOpen ? 'flex-start' : 'center',
        height: '100vh',

    };

    return (
        <>
            <div className="sidebar bg-white" style={sidebarStyle}>
                <div>
                    <div className='p-2 '>
                        <div className='fs-5 text fw-bolder font-monospace'>Main</div>
                    </div>
                    <div className='d-flex align-items-center gap-2 p-2 px-3 sidebarlist '>
                        <MdDashboard size={20} color='#9068be' />
                        {isOpen && <div className='fs-6 text fw-semi-bold  font-Roboto '>Dashboard</div>}
                    </div>

                    {isOpen && (
                        <div className='p-2'>
                            <div className='fs-5 text fw-bolder font-monospace'>Manage order</div>
                        </div>
                    )}
                    <div>
                        <Link to='/pending-status'>
                            <div className='d-flex align-items-center gap-2 p-2 px-3  sidebarlist'>
                                <MdOutlinePendingActions  size={20} color='#9068be' />
                                {isOpen && <div className='fs-6 text fw-semi-bold ms-2 font- Roboto'>Pending Bill</div>}
                            </div>
                        </Link>
                      
                        <Link to='/completed-status'>
                            <div className='d-flex align-items-center gap-2 p-2 px-3  sidebarlist'>
                                <MdIncompleteCircle  size={20} color='#9068be' />
                                {isOpen && <div className='fs-6 text fw-semi-bold ms-2 font- Roboto'>Completed Bill</div>}
                            </div>
                        </Link>


                        {/* <div className='d-flex align-items-center  gap-2 p-2 px-3  sidebarlist'>
                        <FaShippingFast size={20} color='#9068be' />
                        {isOpen && <div className='fs-6 text fw-semi-bold ms-2 font-Roboto'>Ship</div>}
                    </div>
                    <div className='d-flex align-items-center  gap-2 p-2 px-3  sidebarlist'>
                        <IoMdContact size={20} color='#9068be' />
                        {isOpen && <div className='fs-6 text fw-semi-bold ms-2 font-Roboto'>Contact</div>}
                    </div>
                    <div className='d-flex align-items-center gap-2 p-2 px-3  sidebarlist'>
                        <GiConfirmed size={20} color='#9068be' />
                        {isOpen && <div className='fs-6 text fw-semi-bold ms-2 font-Roboto'>Confirmed</div>}
                    </div>
                    <div className='d-flex align-items-center gap-2 p-2 px-3  sidebarlist'>
                        <MdOutlinePendingActions size={20} color='#9068be' />
                        {isOpen && <div className='fs-6 text fw-semi-bold ms-2 font-Roboto'>Pending</div>}
                    </div>
                    <Link to='/client'>
                        <div className='d-flex align-items-center gap-2 p-2 px-3  sidebarlist'>
                            <FaUsers size={20} color='#9068be' />
                            {isOpen &&
                                <div className='fs-6 text fw-semi-bold ms-2 font-Roboto text-color-red'>All Client</div>
                            }
                        </div>
                    </Link> */}
                    </div>
                </div>

            </div >
        </>
    );
}

export default Sidebar;
