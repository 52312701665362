import React, { useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';

function Dashboard() {
    const [sidebarOpen, setSidebarOpen] = useState(true);

    const handleToggle = () => {
        setSidebarOpen(!sidebarOpen); // Toggle the sidebarOpen state
    };

    return (
        <div>
            <Header handleToggle={handleToggle} /> {/* Pass handleToggle function as prop */}
            <div className='d-flex'>
                <Sidebar isOpen={sidebarOpen} /> {/* Pass isOpen state as prop */}
                <div className='container p-5'>
                    <div>Main content Area</div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
